<template>
  <b-table
    class="position-relative"
    :items="items"
    responsive
    :fields="fields"
    :busy="busy"
    :thead-class="!items || items.length == 0 ? 'd-none' : ''"
    primary-key="id"
    show-empty
    empty-text="No matching records found"
  >
    <!-- Column: name with avatar -->
    <template #cell(nameAvatar)="{ item }">
      <b-media class="d-flex align-items-center">
        <b-link :to="getOrganizationLocation(item)" :target="currentCollectiveKey ? '_blank' : '_self'" class="text-dark d-flex align-items-center">
          <safe-img class="logo" :src="getImageResource(item.logoURL)" :placeholder="placeholder" />
          <div class="ml-1">
            <p class="font-weight-extrabold mb-0">
              {{ translate(item.name) }}
            </p>
            <!-- eslint-disable-next-line vue/no-v-html-->
            <small v-if="item.headline" class="text-muted mb-0" v-html="translate(item.headline)" />
          </div>
        </b-link>
      </b-media>
    </template>

    <!-- Loading table -->
    <template #table-busy>
      <div class="text-center p-5">
        <b-spinner
          size="2rem"
          class="d-block m-auto m-1"
          variant="primary"
          label="Loading..."
        />
      </div>
    </template>

    <!-- Empty table -->
    <template #empty>
      <b-row class="horizontal-placeholder w-100">
        <b-col cols="12">
          <img :src="organizationsPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('available.message', { itemName: $t('organizations.title') }) }}
          </p>
        </b-col>
      </b-row>
    </template>
  </b-table>
</template>

<script>
import OrganizationsPlaceholder from '@/assets/images/placeholders/light/organizations.svg';
import AvatarPlaceholder from '@/assets/images/placeholders/light/organizations-logo.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';

export default {
  components: {
    SafeImg,

  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    noShowOrganizations: Boolean,
    isSearching: Boolean,
    filter: Boolean,
    busy: Boolean,
    societies: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fields() {
      return [
        {
          key: 'nameAvatar',
          label: this.$t('name.title'),
          sortable: false,
        },
        // {
        //   key: 'description',
        //   label: this.$t('organizations.edit.description.label'),
        //   sortable: false,
        // },
        /*{
          key: 'actions',
          label: this.$t('backoffice.members.actions'),
          sortable: false,
        },*/
      ];
    },
    currentCollectiveKey() {
      return this.$store.getters.currentCollective.key === '5e26cee0-0cbc-11ed-89a7-a1d49fe606c5';
    },
    organizationsPlaceholder() {
      return OrganizationsPlaceholder;
    },
    placeholder() {
      return AvatarPlaceholder;
    },
    isDetailView() {
      return this.$route.name === 'organizations';
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    }
  },
  methods: {
    getOrganizationLocation(organization) {
      if (!organization.name) {
        return null;
      }
      return {
        name: this.societies ? 'societies-details' : 'backoffice-organization-details',
        params: {
          ...(this.societies ? { organizationId: organization.key } : { slug: organization.slug }),
          communityId: this.$route.params.communityId,
        },
      };
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    getImageResource,
    async toggleLike(item) {
      await this.$store.dispatch('toggleLike', {
        itemType: 'communitiesOthers',
        storedKey: 'organizations',
        morphType: 'organization',
        key: item.key,
      });
      if (item.likedByMember) {
        item.likedByMember = false;
        --item.totalLikes;
      } else {
        item.likedByMember = true;
        ++item.totalLikes;
      }
    },
  },

};
</script>

<style>

</style>
