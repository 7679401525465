<template>
  <div class="w-100 p-0 overflow-hidden">
    <app-scroll>
      <section v-if="!noShowOrganizations || isSearching || filter">
        <backoffice-organizations
          v-if="isForBackoffice"
          :items="organizations2"
          :no-show-organizations="noShowOrganizations"
          :filter="filter"
          :is-searching="isSearching"
          :busy="busy"
          :societies="societies"
        />
        <section v-else>
          <organization-table-mobile
            v-if="isMobile"
            :items="organizations2"
            :no-show-organizations="noShowOrganizations"
            :filter="filter"
            :is-searching="isSearching"
            :busy="busy"
            :societies="societies"
          />
          <organization-table-web
            v-else
            :items="organizations2"
            :no-show-organizations="noShowOrganizations"
            :filter="filter"
            :is-searching="isSearching"
            :busy="busy"
            :societies="societies"
          />
        </section>
      </section>
      
      <!-- No organizations -->
      <b-row v-else class="horizontal-placeholder w-100">
        <b-col cols="12">
          <img :src="organizationsPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('addons.organizations.no-show-message') }}
          </p>
          <!-- Hardcoding for latamfm -->
          <div v-if="isLatamFM" class="d-block mx-auto customText mt-n2">
            <div class=" text-right ">
              <feather-icon
                v-if="canEdit && !isEditing"
                icon="Edit2Icon"
                size="18"
                style="cursor: pointer"
                class="text-primary ml-1 mb-50"
                role="button"
                @click="isEditing = true"
              />
              <feather-icon
                v-if="canEdit && isEditing"
                icon="SaveIcon"
                size="18"
                style="cursor: pointer"
                class="text-primary ml-1 mb-50"
                role="button"
                @click="saveAddon"
              />
            </div>
            <b-alert
              v-if="!isEditing"
              :show="!isEditing"
              variant="primary"
              class="pb-0"
            >
              <span v-html="customText" />
            </b-alert>
            <quill-editor
              v-else
              id="custom-text"
              v-model="customText"
              class="quillE"
            />
          </div>
        </b-col>
      </b-row>
    </app-scroll>
  </div>
</template>

<script>
import OrganizationsPlaceholder from '@/assets/images/placeholders/light/organizations.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { getImageResource } from '@/@core/utils/image-utils';
import AvatarPlaceholder from '@/assets/images/placeholders/light/organizations-logo.svg';
import Ripple from 'vue-ripple-directive';
import AppScroll from '@/@core/components/app-scroll/AppScroll.vue';
import { quillEditor } from 'vue-quill-editor';
import { checkPermissions } from '@/@core/utils/roles-utils';
import {
  ENABLED_APPS_GETTERS,
} from '@/store/enabled-apps/enabled-apps-store-constants';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import OrganizationTableWeb from './OrgnizationTableWeb.vue';
import OrganizationTableMobile from './OrganizationTableMobile.vue';
import BackofficeOrganizations from './BackofficeOrganizations.vue';

export default {
  name: 'OrganizationsTable',
  components: {
    AppScroll,
    quillEditor,
    OrganizationTableWeb,
    OrganizationTableMobile,
    BackofficeOrganizations
  },
  directives: {
    Ripple,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    organizations: {
      type: [Array, Function],
      default: () => [],
    },
    isForBackoffice: {
      type: Boolean,
      default: false
    },
    busy: Boolean,
    noShowOrganizations: Boolean,
    isSearching: Boolean,
    filter: Boolean,
    societies: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      organizations2: [],
      customNames: [],
      isEditing: false,
      customText: 'En este directorio de proveedores, las más de 3.000 empresas listadas se pueden buscar por país y por servicio',
    };
  },
  computed: {
    canEdit() {
      return (
        this.$store.getters.currentCollective.isStaff
        || checkPermissions(
          'update',
          'organization',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    showOrgs() {
      return !this.noShowOrganizations || this.isSearching || this.filter;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    isLatamFM() {
      return this.$store.getters.currentCollective.key === '5e26cee0-0cbc-11ed-89a7-a1d49fe606c5';
    },
    fields() {
      return [
        {
          key: 'nameAvatar',
          label: this.$t('name.title'),
          sortable: false,
        },
        {
          key: 'description',
          label: this.$t('organizations.edit.description.label'),
          sortable: false,
        },
        /*{
          key: 'actions',
          label: this.$t('backoffice.members.actions'),
          sortable: false,
        },*/
      ];
    },
    organizationsPlaceholder() {
      return OrganizationsPlaceholder;
    },
    isMobile() {
      return window.innerWidth < 700;
    },
    placeholder() {
      return AvatarPlaceholder;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    isDetailView() {
      return this.$route.name === 'organizations';
    },
    app() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].organizations;
    },
  },
  watch: {
    locale() {
      if (this.isDetailView) {
        this.createFieldsColumns();
      }
    },
  },
  created() {
    // console.log('this.noShowOrganizations', this.noShowOrganizations);
    // console.log('this.isSearching', this.isSearching);
    // console.log('this.filter', this.filter);
    
    this.organizations2 = this.organizations.map((item) => item);
    if (this.isDetailView) {
      this.createFieldsColumns();
    }
    this.customText = this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]?.organizations?.tagline ? Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]?.organizations?.tagline)[0] : '';
  },
  methods: {
    createFieldsColumns() {
      this.organizations2.map((item) => {
        item.custom?.map((i) => {
          const field = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.customNames.push(field);
        });
      });
      this.dataFields();
      this.customNames = [...new Set(this.customNames)];
      return this.customNames.map((item, index) => {
        this.fields.splice(2, 0, {
          key: item,
          label: item,
          sortable: false,
          thClass: 'pl-50',
          formatter: (value) => {
            if (typeof value === 'boolean') {
              if (value === true) {
                return this.$t(
                  'organizations.edit.custom-fields.boolean-response.true',
                );
              }
              if (value === false) {
                return this.$t(
                  'organizations.edit.custom-fields.boolean-response.false',
                );
              }
            }
            return value;
          },
        });
      });
    },
    dataFields() {
      this.organizations2.map((n, index) => {
        n.custom?.map((i) => {
          const typeName = i?.customFieldName[this.locale] ? i.customFieldName[this.locale] : Object.values(i.customFieldName)[0];
          this.organizations2[index][typeName] = i.value;
        });
      });
    },
    getOrganizationLocation(organization) {
      if (!organization.name) {
        return null;
      }
      return {
        name: this.societies ? 'societies-details' : 'organization',
        params: {
          ...(this.societies ? { organizationId: organization.key } : { slug: organization.slug }),
          communityId: this.$route.params.communityId,
        },
      };
    },
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    getImageResource,
    async toggleLike(item) {
      await this.$store.dispatch('toggleLike', {
        itemType: 'communitiesOthers',
        storedKey: 'organizations',
        morphType: 'organization',
        key: item.key,
      });
      if (item.likedByMember) {
        item.likedByMember = false;
        --item.totalLikes;
      } else {
        item.likedByMember = true;
        ++item.totalLikes;
      }
    },
    async saveAddon() {
      this.isEditing = false;
      try {
        await this.$store.dispatch('changeTaglineApp', {
          appID: 32,
          tagline: { es: this.customText },
        });

        this.notifySuccess(this.$t('integrations.messages.success'));
      } catch {
        this.notifyError(this.$t('integrations.messages.error'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  object-fit: contain;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.customText {
  max-width: 800px;
}
</style>
